import { FaceSmileIcon, MicrophoneIcon, SparklesIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import Subscribe from "./Subscribe";
const features = [
  {
    name: "Face Swap",
    description:
      "Our AI-powered technology seamlessly swaps the face in your videos with an avatar of your choice. Experience the thrill of transformation without any complex editing.",
    icon: FaceSmileIcon,
  },
  {
    name: "Voice Change",
    description: "Change the voice in your videos to match your new avatar. Our advanced AI models ensure a natural-sounding voice change that aligns perfectly with the video.",
    icon: MicrophoneIcon,
  },
  {
    name: "Custom Avatar Creation",
    description:
      "Create your own unique avatars using our user-friendly interface. Mix and match different features to create a realistic or fantastical persona that fits your needs.",
    icon: Squares2X2Icon,
  },
  {
    name: "Simplicity Meets Power",
    description:
      "Our platform is designed to be both powerful and easy to use. Transform your videos in a few clicks, without any technical expertise or sophisticated editing software.",
    icon: SparklesIcon,
  },
];

const faqs = [
  {
    id: 1,
    question: "What is OtherYou and what can I do with it?",
    answer: "OtherYou is an AI tool that transforms your video content by changing face and voice with selected avatars and models.",
  },
  {
    id: 2,
    question: "How does the face and voice replacement work?",
    answer: "Our AI 'studies' the selected avatar and voice model, then applies their characteristics to your video.",
  },
  {
    id: 3,
    question: "Is it complicated to use OtherYou?",
    answer: "No, it's user-friendly. Upload your video, choose your avatar and voice, and let our AI do the rest.",
  },
  {
    id: 4,
    question: "Can I create my own custom avatar?",
    answer: "Yes, you can create custom avatars for your video transformations with OtherYou.",
  },
  {
    id: 5,
    question: "Is my data safe with OtherYou?",
    answer: "Absolutely. We use secure, encrypted connections for data transfers and don't share your data without consent.",
  },
];

export default function Home() {
  return (
    <div className="bg-white">
      <main className="isolate" id="main">
        {/* Hero section */}
        <div className="relative pt-14">
          <div className="pb-0 sm:pt-32 sm:pb-0">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Become Anyone You Want in Your Videos with OtherYou</h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">Choose from lifelike avatars and voice models, upload your video, and watch yourself transform in moments.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="/signin"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started for free
                  </a>
                  <a href="#feature-section" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-16">
                <div className="flex justify-center">
                  <video
                    src="https://firebasestorage.googleapis.com/v0/b/otheryou.appspot.com/o/home2.mp4?alt=media&token=ef66bb51-031c-4148-b7bd-328f54febf8e&_gl=1*1fv8v3o*_ga*Mzc1MjQ3MDA3LjE2OTEwNTM0ODA.*_ga_CW55HF8NVT*MTY5OTA0NDk5MC41NS4xLjE2OTkwNDUwMjEuMjkuMC4w"
                    loop
                    controls
                    className="w-[750px] h-[540px] bg-[#F7F6F9] rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Feature section */}
        <div id="feature-section" className="mx-auto pt-2 mt-8 sm:pt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Virtual Avatars</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">State-of-the-Art AI Models to Replace Face and Voice in a Video</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Harness the power of advanced artificial intelligence to transform the identity in your videos with unmatched precision and realism.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Pricing section */}
        <div id="pricing-section" className="py-24 sm:pt-48">
          <Subscribe isLanding />
        </div>

        {/* FAQs */}
        <div id="faq-section" className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* CTA section */}
        <div className="relative -z-10 mt-32 px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Start Creating Your Virtual Self Today!</h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">Experience the power of AI and step into a new identity. Click 'Get Started' to begin.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/signin"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
        <footer aria-labelledby="footer-heading" className="relative border-t border-gray-900/10 py-6 sm:mt-56 sm:py-6">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="text-center">
            <a href="/terms" className="text-sm leading-6 text-indigo-600 hover:text-gray-900">
              Terms
            </a>
            <span className="text-sm leading-6 text-gray-600 hover:text-gray-900"> & </span>
            <a href="/privacy" className="text-sm leading-6 text-indigo-600 hover:text-gray-900">
              Privacy
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
