import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ReactComponent as UserSvg } from "../assets/icons/user.svg";
import { ReactComponent as LogoSVG } from "../assets/images/logo.svg";
import { useCustomerStore } from "../domains/customer/customer.store";
import { useSubscriptionStore } from "../domains/subscription/subscription.store";

const navigation = [
  { name: "Product", href: "/#main" },
  { name: "Features", href: "/#feature-section" },
  { name: "Pricing", href: "/#pricing-section" },
  { name: "FAQ", href: "/#faq-section" },
];

function Menu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { customer } = useCustomerStore();
  const { subscription } = useSubscriptionStore();
  console.log(subscription);

  if (customer)
    return (
      <div>
        <div className={`bg-[#F7F6F9] h-[80px] drop-shadow-md flex items-center px-3 md:px-7`}>
          <a className="flex items-center" href="/dashboard">
            <LogoSVG className="h-7 w-7 mr-2" />
            <div className="hidden lg:block font-bold mr-2">
              Other<span className="text-[#6A12F3] font-extrabold">You</span>
            </div>
            <div className="bg-[#BEBDBD] text-sm rounded-full font-bold px-2 mr-4">beta</div>
          </a>
          <div className="flex-1"></div>

          <div className="flex items-center justify-center">
            {subscription?.status === "no-subscription" && (
              <a href="/subscribe" className="text-xs font-bold bg-[#6A12F3] mr-5 px-4 py-2 text-white rounded-full cursor-pointer active:opacity-50">
                Subscribe
              </a>
            )}
            <div className="flex flex-col items-center mr-3">
              <a className="flex items-center active:opacity-50" href="/settings">
                <div className="text-sm  font-bold uppercase mr-1">Credit</div>
                <div className="bg-[#6A12F3] text-white text-sm rounded-full font-bold px-2 mr-4">{customer.credits}</div>
              </a>
            </div>
            <a className="w-10 h-10 bg-[#BEBDBD] rounded-[10px] flex items-center justify-center active:opacity-50" href="/settings">
              <UserSvg className="w-7 h-7" />
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center"></div>
      </div>
    );
  else
    return (
      <div className="h-[80px]">
        <header className="fixed lg:absolute inset-x-0 top-0 z-50 px-3 bg-[#F7F6F9] drop-shadow-md">
          <nav className="flex items-center justify-between h-[80px] lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
              <a className="flex items-center" href="/">
                <LogoSVG className="h-7 w-7 mr-2" />
                <div className="hidden lg:block font-bold mr-2">
                  Other<span className="text-[#6A12F3] font-extrabold">You</span>
                </div>
                <div className="bg-[#BEBDBD] text-sm rounded-full font-bold px-2 mr-4">beta</div>
              </a>
            </div>
            <div className="flex lg:hidden">
              <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="/signin" className="text-sm font-semibold leading-6 text-white bg-[#6A12F3] px-5 py-2 rounded-md focus:opacity-50">
                Log in
              </a>
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
                </a>
                <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a key={item.name} href={item.href} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a href="/signin" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
    );
}

export default Menu;
